
    import Loading from '~/components/Loading/Loading';
    import Header from '~/components/blocks/header/Header';
    import Footer from '~/components/blocks/footer/Footer';

    import PageTitle from '~/components/blocks/index/PageTitle';
    import AboutMe from '~/components/blocks/index/AboutMe';
    import WhatIDo from '~/components/blocks/index/WhatIDo';
    import Experience from '~/components/blocks/index/Experience';
    import Education from '~/components/blocks/index/Education';
    import Portfolio from '~/components/blocks/index/Portfolio';


    import Testimonials from '~/components/blocks/index/Testimonials';
    import ContactMe from '~/components/blocks/index/ContactMe';
    import Articles from '~/components/blocks/index/Articles';
    import SeoFriendlyData from "~/mixins/seo-friendly-data";

    import api from "../mixins/api";

    export default {
        components: {
            Loading,
            Header,
            PageTitle,
            AboutMe,
            WhatIDo,
            Experience,
            Education,
            Portfolio,
            Testimonials,
            ContactMe,
            Articles,
            Footer
        },
        mixins:[api],
        data(){
          return {
            entities: {},
            testimonials: [],
            news: {
              data: []
            },
            programs: {
              data: []
            }
          }
        },
        async fetch() {
          const seo = new SeoFriendlyData();
          const [entities] = await Promise.all([
            seo.loadData(
                this.$nuxt.context,
                `public/get-entities/home|contactForm`
            ),
          ]);
          let sortedEntities = {};
          if(entities) {
            for (let entity of entities.data) {
              sortedEntities[entity.entity_call] = entity;
            }
          }
          this.entities = sortedEntities;
        },
        created(){
          this.getPrograms();
          this.getTestimonials();
          this.getNews();
        },
        mounted: function() {
            if(process.client) {
              document.body.classList.add('home');
              document.body.classList.add('header-absolute-true');
              document.body.classList.add('header-fixed-true');
              document.body.classList.add('header-content-secondary-true');
              document.body.classList.add('header-fixed-content-primary');
              document.body.classList.add('aos-true');
              this.$aosAnimate();
            }
        },
        beforeDestroy() {
          if(process.client) {
            document.body.classList.remove('home');
            document.body.classList.remove('header-absolute-true');
            document.body.classList.remove('header-fixed-true');
            document.body.classList.remove('header-content-secondary-true');
            document.body.classList.remove('header-fixed-content-primary');
            document.body.classList.remove('aos-true');
          }
        },
        metaInfo: {
            title: 'Home | Sely - Personal Resume vCard Vue JS Template',
            titleTemplate: '%s'
        },
        methods: {
          async getNews(){
            const news = await this.get("public/get-news");
            if(news){
              this.news = news;
            }
          },
          async getTestimonials(){
              const testimonials =  await this.get("public/testimonials/get-random");
              if(testimonials){
                this.testimonials = testimonials;
              }
          },
          async getPrograms(){
            const programs = await this.get("public/get-recent-programs");
            if(programs){
              this.programs = programs;
            }
          }
        }
    }
