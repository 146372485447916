
    import api from "../../mixins/api";
    import InvalidFeedback from "./Fields/InvalidFeedback.vue";
    export default {
        name: 'ContactsForm',
        components: {
          InvalidFeedback
        },
        mixins: [api],
        data() {
            return {
                form: {
                  authorName: '',
                  authorEmail: '',
                  authorComment: ''
                },
                successMessage: "Sender's message was sent successfully",
                warningMessage: 'Fill up the form, please!',
                errorMessage: 'Something go wrong. Try again later!',
                responseMessage: '',
                alertClass: '',
                callAlert: false
            }
        },
        computed: {
          errorFields() {
            return this.$store.state.errorFields;
          },
          formErrors() {
            return this.$store.state.errors;
          },
        },
        methods: {

          async submitForm() {
            const requestSent = await this.post("public/store-client-container/cform", this.form);
            if(requestSent){
              this.form = {
                authorName: '',
                authorEmail: '',
                authorComment: ''
              }
              this.responseMessage = this.successMessage;
              this.alertClass = "success";
              this.callAlert  = true;
              setTimeout( () => {
                this.callAlert = false;
              }, 2000 )
            }
          }
        }
    }
