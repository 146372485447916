
    import BlogData from '~/data/blog/blogData.json';

    export default {
        name: 'Articles',
        props: {
          data: {
            type: Object,
            default: {}
          }
        },
        data() {
            return {
                items: BlogData.blogData.slice(0, 3),
                sliderOptions: {
                  autoplay: false,
                  centerMode: true,
                  dots: false,
                  navButtons: false,
                  infinite: false,
                  initialSlide: 1,
                  slidesToShow: 3,
                  responsive: [
                    {
                      breakpoint: 200,
                      settings: {
                        slidesToShow: 1,
                      }
                    },
                    {
                      breakpoint: 600,
                      settings: {
                        slidesToShow: 2,
                      }
                    },

                    {
                      breakpoint: 900,
                      settings: {
                        slidesToShow: 3,
                      }
                    }
                  ]
                },
            }
        },
        mounted() {
            const lastEl = document.querySelectorAll('#articles .card .card-top')[2];
            lastEl.setAttribute('data-aos-delay', 600);
        }
    }
