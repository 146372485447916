
    export default {
        name: 'Portfolio',
        props: {
          programs: {
            type: Object,
            default: {
              data: []
            }
          }
        },
        data() {
            return {
                sliderOptions: {
                  autoplay: false,
                  centerMode: true,
                  dots: false,
                  navButtons: false,
                  infinite: false,
                  initialSlide: 1,
                  slidesToShow: 4,
                  responsive: [
                    {
                      breakpoint: 200,
                      settings: {
                        slidesToShow: 1,
                      }
                    },
                    {
                      breakpoint: 600,
                      settings: {
                        slidesToShow: 2,
                      }
                    },

                    {
                      breakpoint: 900,
                      settings: {
                        slidesToShow: 3,
                      }
                    }
                  ]
                },
            }
        },
        mounted() {

        }
    }
