
    export default {
        name: 'Experience',
        props: {
          data: {
            type: Array,
            default: []
          }
        },
        data() {
            return {
                startDelay: 200,
                sliderOptions: {
                  autoplay: false,
                  centerMode: true,
                  dots: false,
                  navButtons: false,
                  infinite: false,
                  initialSlide: 1,
                  slidesToShow: 3,
                  responsive: [
                    {
                      breakpoint: 200,
                      settings: {
                        slidesToShow: 1,
                      }
                    },
                    {
                      breakpoint: 600,
                      settings: {
                        slidesToShow: 2,
                      }
                    },

                    {
                      breakpoint: 900,
                      settings: {
                        slidesToShow: 3,
                      }
                    }
                  ]
                },
            }
        },
        methods: {
          prevSlide(){

          },
          nextSlide(){

          }
        }
    }
