
    import ContactsForm from '~/components/Forms/ContactsForm';

    export default {
        name: 'Contact-me',
        props: {
          data: {
            type: Object,
            default: {}
          }
        },
        components: {
            ContactsForm
        }
    }
