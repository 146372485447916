
    export default {
        name: 'About-me',
        props: {
        data: {
          type: Object,
          default: {}
        }
      },
    }
