
    import SocialLinks from '../General/SocialLinks';

    export default {
        name: 'PageTitle',
        props: {
          data: {
            type: Object,
            default: {}
          }
        },
        components: {
            SocialLinks,
        },
        mounted() {
            if ( document.querySelector('.page-title-img') ) {
                document.querySelector('.site-main').classList.add('overflow-hidden');
            }
        },
        beforeDestroy() {
            if ( document.querySelector('.site-main').classList.contains('overflow-hidden') ) {
                document.querySelector('.site-main').classList.remove('overflow-hidden');
            }
        }
    }
