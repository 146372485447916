
    export default {
        name: 'What-i-do',
        props: {
          data: {
            type: Object,
            default: {}
          }
        },
        data() {
            return {
                startDelay: 200,
            }
        }
    }
