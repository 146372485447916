
    import SocialLinksData from '~/data/index/socialLinksData.json';

    export default {
        name: 'Page-title-menu-secondary',
        props: {
          data: {
            type: Object,
            default: {}
          }
        },
    }
