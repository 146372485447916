export default class SeoFriendlyData {
  loadData(ctx, url) {
      ctx.$axios.setHeader("X-Client", ctx.store.state.initializedId);
      return ctx.$axios
      .get(`${process.client ? ctx.$config.coreURL : ctx.$config.coreInitURL}/_cmt/${url}`)
      .then(({data} ) => {
        return data;
      })
      .catch((e) => {
        console.log("Caught ERR on GET request");
        console.log(e);
      });
  }
}
